/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { observer } from 'mobx-react';
import { useStores } from '@stores';
import { DocumentAccordion } from '@components/DocumentAccordion/DocumentAccordion';
// @ts-ignore
import featuredImage from '../../resources/images/liinakonkatu.png';

interface ILivingIndex {
  data: IMarkdownData;
}

const LivingIndexPage: FC<ILivingIndex> = observer(() => {
  const {
    agreementStore: {
      tenantsDocuments: allTenantsDocuments, selectedAgreementId, documentState,
      getTenantsDocuments,
    }
  }: {
    agreementStore: IAgreementStore
  } = useStores();

  useEffect(() => {
    if (documentState !== 'Loading' && documentState !== 'Error' && selectedAgreementId && !allTenantsDocuments.find((tenantDocObj) => tenantDocObj.agreement_id === selectedAgreementId)) {
      getTenantsDocuments(selectedAgreementId);
    }
  }, [documentState, selectedAgreementId, allTenantsDocuments, getTenantsDocuments]);

  const tenantsDocuments = allTenantsDocuments.find((docObj) => docObj.agreement_id === selectedAgreementId)?.documents;

  return (
    <ContentPageLayout featuredImageFile={featuredImage} slug="living">
      <DocumentAccordion loading={documentState === 'Loading'} documentType="tenantsdocuments" items={tenantsDocuments as IDocument[]} />
    </ContentPageLayout>
  );
});
export default LivingIndexPage;
